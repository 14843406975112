import { Controller } from "@hotwired/stimulus";

import initializeCampaignChannel from "../channels/campaign_channel";

export default class extends Controller {
  static values = {
    campaignId: Number,
    expectingStatus: String,
  };

  connect() {
    this.campaignChannel = initializeCampaignChannel(
      this.campaignIdValue,
      this.reloadPage.bind(this)
    );

    this.timeoutId = window.setTimeout(this.reloadPage.bind(this), 30 * 1000);
  }

  disconnect() {
    window.clearTimeout(this.timeoutId);
    this.campaignChannel.unsubscribe();
  }

  reloadPage(data) {
    if (!data || data.status === this.expectingStatusValue) {
      Turbo.cache.clear();
      Turbo.visit(window.location, { action: "replace" });
    }
  }
}
