import { Controller } from "@hotwired/stimulus";

const THREE_SECONDS = 3_000;

export default class extends Controller {
  static targets = ["form"];
  static values = {
    clientToken: String,
    couponCode: String,
    email: String,
    getTransactionPath: String,
    planId: String,
    sandbox: Boolean,
    userUuid: String,
  };

  connect() {
    if (window.Paddle) {
      this.handleOnload();
    } else {
      const script = document.createElement("script");
      script.addEventListener("load", this.handleOnload.bind(this), {
        once: true,
      });
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      script.async = true;

      this.scriptElement = document.head.appendChild(script);
    }
  }

  disconnect() {
    this.scriptElement?.remove();
    window.clearTimeout(this.timeoutId);
  }

  handleOnload() {
    if (this.sandboxValue) {
      Paddle.Environment.set("sandbox");
    }

    Paddle.Setup({
      token: this.clientTokenValue,
      checkout: {
        settings: {
          allowLogout: false,
          displayMode: "overlay",
          locale: "en",
          theme: "light",
        },
      },
      eventCallback: this.handleEventCallback.bind(this),
    });
  }

  showCheckout(event) {
    event.preventDefault();

    Paddle.Checkout.open({
      customData: { user_uuid: this.userUuidValue },
      customer: { email: this.emailValue },
      discountCode: this.couponCodeValue,
      items: [{ priceId: this.planIdValue, quantity: 1 }],
      settings: { allowLogout: false },
    });
  }

  changePaymentMethod(event) {
    event.preventDefault();

    fetch(this.getTransactionPathValue, {
      credentials: "same-origin",
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        const transactionId = json.ext_transaction_id;
        Paddle.Checkout.open({ transactionId });
      });
  }

  handleEventCallback(checkoutEvent) {
    if (checkoutEvent.name === "checkout.completed") {
      Paddle.Checkout.close();
      Paddle.Spinner.show();

      this.timeoutId = window.setTimeout(() => {
        this.handleSuccessCallback(checkoutEvent.data);
      }, THREE_SECONDS);
    }
  }

  handleSuccessCallback(data) {
    if (this.formTarget.ext_transaction_id) {
      this.formTarget.ext_transaction_id.value = data.transaction_id;
    }
    this.formTarget.submit();
  }
}
