import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    extCustomerId: String,
    key: String,
  };

  connect() {
    this.element.id = "profitwell-js";
    this.element.dataset.pwAuth = this.keyValue;

    if (window.profitwell) {
      this.handleOnload();
    } else {
      window.profitwell = function () {
        (window.profitwell.q = window.profitwell.q || []).push(arguments);
      };

      const script = document.createElement("script");
      script.addEventListener("load", this.handleOnload.bind(this), {
        once: true,
      });
      script.src = `https://public.profitwell.com/js/profitwell.js?auth=${this.keyValue}`;
      script.async = true;
      this.scriptElement = document.head.appendChild(script);
    }
  }

  disconnect() {
    this.scriptElement?.remove();
  }

  handleOnload() {
    profitwell("user_id", this.extCustomerIdValue);
  }
}
