// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import LocalTime from "local-time";
import * as Sentry from "@sentry/browser";

import "./controllers";

LocalTime.start();

const sentryDsn = document
  .querySelector('meta[name="sentry-dsn"]')
  ?.getAttribute("content");
sentryDsn && Sentry.init({ dsn: sentryDsn });
const userUuid = document
  .querySelector('meta[name="user-uuid"]')
  ?.getAttribute("content");
userUuid && Sentry.setUser({ uuid: userUuid });
