import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["canvas"];
  static values = {
    data: Object,
  };

  connect() {
    const chartLibrary = global.Chartkick?.adapters[0]?.library;
    if (!chartLibrary || !global.geoCountriesData) {
      this.element.remove();
      return;
    }

    new chartLibrary(this.canvasTarget.getContext("2d"), {
      type: "choropleth",
      data: {
        labels: global.geoCountriesData.map((d) => d.properties.name),
        datasets: [
          {
            label: "Countries",
            data: global.geoCountriesData.map((d) => ({
              feature: d,
              value: this.dataValue[d.id] || 0,
            })),
          },
        ],
      },
      options: {
        animation: false,
        showOutline: true,
        showGraticule: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
          },
        },
        scales: {
          color: {
            axis: "x",
            interpolate: "interpolateGreys",
            min: 0,
          },
          projection: {
            axis: "x",
            projection: "equalEarth",
          },
        },
      },
    });
  }
}
