import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    sandbox: Boolean,
    sellerId: Number,
    updateUrl: String,
  };

  connect() {
    if (window.Paddle) {
      this.handleOnload();
    } else {
      const script = document.createElement("script");
      script.addEventListener("load", this.handleOnload.bind(this), {
        once: true,
      });
      script.src = "https://cdn.paddle.com/paddle/paddle.js";
      script.async = true;

      this.scriptElement = document.head.appendChild(script);
    }
  }

  disconnect() {
    this.scriptElement?.remove();
  }

  handleOnload() {
    if (this.sandboxValue) {
      Paddle.Environment.set("sandbox");
    }

    Paddle.Setup({
      vendor: this.sellerIdValue,
    });
  }

  changePaymentMethod(event) {
    event.preventDefault();

    Paddle.Checkout.open({
      override: this.updateUrlValue,
      successCallback: this.handleSuccessCallback.bind(this),
    });
  }

  handleSuccessCallback(data) {
    Paddle.Spinner.show();
    this.formTarget.submit();
  }
}
