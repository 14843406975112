import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["error"];
  static targets = ["button"];
  static values = { nextPagePath: String };

  loadMore(event) {
    event.preventDefault();

    this.errorElement?.remove();

    fetch(this.nextPagePathValue, {
      credentials: "same-origin",
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const nextPagePath = response.headers.get("X-Next-Page-Path");
        this.nextPagePathValue = nextPagePath || undefined;

        return response.text();
      })
      .then((text) => {
        this.buttonTarget.insertAdjacentHTML("beforebegin", text);

        if (!this.hasNextPagePathValue) {
          this.buttonTarget.remove();
        }
      })
      .catch(() => {
        this.errorElement = document.createElement("p");
        this.errorElement.classList.add(this.errorClass);
        this.errorElement.innerText =
          "There was an error with your request, please try again later";
        this.buttonTarget.insertAdjacentElement("afterend", this.errorElement);
      });
  }
}
